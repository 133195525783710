body {
  margin: 0;
  background-color: #1e2024; /* Dark background color */
  color: white; /* Text color */
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.react-financial-charts-ew-resize-cursor {
  cursor: pointer;
}

.react-no-select {
  user-select: none;
}
.grid-layout {
  background-color: #282c34; /* Dark background color for the container */
  padding: 20px; /* Add padding if needed */
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34; /* Dark background color for the container */
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  align-items: center;
}

/* You can customize Ant Design components' styles here as well */
/* For example, changing the primary button color to a different shade of blue */
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

/* Additional styles */
/* Set background and text color for the entire app */
.App {
  background-color: #282c34;
  color: white;
}

/* Dark background for the grid cell */
.react-grid-item {
  background-color: #282c34;
}

.horizontal-buttons {
  display: flex;
  align-items: center;
}

.button-spacing {
  margin-right: 10px; /* Adjust the spacing as needed */
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 10px;
}

.account-info {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap as needed */
}

.account-info p {
  margin: 0; /* Remove default margins */
}

.about-site {
  font-size: 14px;
  color: #666; /* Adjust the color as needed */
  margin-bottom: 15px;
}

.react-grid-item {
  background-color: #575656; /* Set the background color */
  border: 1px solid #999; /* Add a border */
  box-sizing: border-box; /* Make sure padding is included in width */
}

/* Styling for the resizing handles */
.react-resizable-handle {
  background-color: #666; /* Set the color of the resizing handle */
}

.remove:hover {
  background-color: #aaa;
}

/* styles.css */

.button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.button:hover {
  background-color: #45a049;
}

.button-spacing {
  margin-right: 10px;
}

.hide-button {
  background-color: #125992; /* Bluish color for Hide button */
  color: white;
}

.show-button {
  background-color: green; /* Grayish color for Show button */
  color: white;
}

.smaller-button {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Smaller button hover effect */
.smaller-button:hover {
  background-color: #0d8aec;
}

/* Smaller button click effect */
.smaller-button:active {
  background-color: #065bbd;
}

/* styles.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1e2024;
  border-bottom: 1px solid #1e2024;
}

.logo img {
  display: block;
}

.metamask-login {
  display: flex;
  align-items: center;
}

/* index.css */
.footer-nav a {
  color: white;
  text-decoration: none;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.footer-nav a:visited {
  color: white; /* This ensures the link remains white even after being clicked */
}
